import React from 'react'

import SearchBar from '@feature/leaderboard/components/SearchBar/SearchBar'
import LeaderboardTabNavigation, {
    LeaderboardTab,
} from '@feature/leaderboard/components/LeaderboardTabNavigation/LeaderboardTabNavigation'
import {useRouter} from 'next/router'
import PageConfig from '@component/layouts/PageConfig/PageConfig'
import useNavigation from '@hook/useNavigation'
import {dehydrate, QueryClient} from '@tanstack/react-query'
import {QueryCacheKeys} from '@hook/query/queryKeys'
import dynamic from 'next/dynamic'
import {getLeaderboardBanners} from '@api/leaderboard/banner/getLeaderboardBanners'
import {createLog} from '@util/logs'
import {getMessages} from '@lib/intl/intl'
import Head from 'next/head'
import {useTranslation} from '@lib/intl/useTranslations'

const LeaderboardCarousel = dynamic(import('@feature/leaderboard/components/LeaderboardCarousel/LeaderboardCarousel'), {
    ssr: false,
    loading: () => <div className={'h-[320px] sm:h-[270px] w-full'} />,
})

const LeaderboardScreenMain = dynamic(import('@feature/leaderboard/pages/ScreenMain/LeaderboardScreenMain'), {
    ssr: false,
})
const LeaderboardScreenCopyLeader = dynamic(
    import('@feature/leaderboard/pages/ScreenCopyLeader/LeaderboardScreenCopyLeader'),
    {
        ssr: false,
    },
)
const LeaderboardScreenLeague = dynamic(import('@feature/leaderboard/pages/ScreenLeague/LeaderboardScreenLeague'), {
    ssr: false,
})

const ScreenLeagueList = dynamic(import('@feature/leaderboard/pages/ScreenPreviousLeague/ScreenLeagueList'), {
    ssr: true,
})

const ProNoticeBottomSheetCSR = dynamic(
    import('@feature/leaderboard/components/ProNoticeBottomSheet/ProNoticeBottomSheet'),
    {
        ssr: false,
    },
)

export const runtime = 'experimental-edge'
const LeaderboardPage = ({banners}) => {
    const t = useTranslation()
    const {query, isReady} = useRouter()
    const tab = (query?.tab as LeaderboardTab) || 'main'

    const {goLeaderboard} = useNavigation()
    const onChangeTab = (tab: LeaderboardTab) => {
        createLog('event', 'leaderboard_page_tab', {page: tab})
        goLeaderboard(tab)
    }

    return (
        <PageConfig enableFloatingNavigation={true} navTheme={'dark'} pageName={'home'}>
            <Head>
                <title>{t('newLeaderboard.layout.nav.menu.mainLeaderboard')}</title>
            </Head>
            <div className={'layout-full_desktop overflow-hidden min-h-screen dark:bg-bg_dark_background'}>
                <LeaderboardCarousel data={banners} />
                <div className={'max-w-1366px mx-auto w-full default_page_padding'}>
                    <SearchBar />
                    <LeaderboardTabNavigation activeTab={tab || 'main'} onChangeActiveTab={onChangeTab} />
                    {tab === 'main' && <LeaderboardScreenMain />}
                    {tab === 'copyleader' && <LeaderboardScreenCopyLeader />}
                    {tab === 'league' && <LeaderboardScreenLeague />}
                    {tab === 'league-all' && <ScreenLeagueList />}
                </div>
            </div>
            <ProNoticeBottomSheetCSR />
        </PageConfig>
    )
}

export const getServerSideProps = async ctx => {
    const locale = ctx.locale
    const queryClient = new QueryClient()

    const {data: leaderDashboard} = await getLeaderboardBanners(locale)
    queryClient.setQueryData(QueryCacheKeys.leaderboard.getBanners(locale), leaderDashboard)

    return {
        props: {
            banners: leaderDashboard,
            dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
            messages: await getMessages(locale),
        },
    }
}

export default LeaderboardPage
